import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-bottom: ${p => p.theme.spacing.huge};

  ul.plans {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    list-style: none;
    gap: 20px;

    @media (max-width: 768px) {
      flex-wrap: wrap;

      li {
        width: 100% !important;
      }
    }

    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      flex-grow: 1;
      border-radius: 10px;
      width: 33%;
      overflow: hidden;
      background-color: ${p => p.theme.colors.secondaryBackgroundColor};
      padding: ${p => `${p.theme.spacing.medium} 2px 2px`};

      h3,
      h4,
      h5,
      p {
        color: #fff;
        margin-bottom: ${p => p.theme.spacing.small};
      }

      h3 {
        font-size: 24px;
      }

      h5 {
        font-size: 26px;
        font-weight: 400;

        strong {
          font-weight: 500;
        }
      }

      p {
        margin-top: ${p => p.theme.spacing.large};
        margin-bottom: ${p => p.theme.spacing.medium};
      }

      a,
      a > div {
        width: 100%;
        border-radius: 0;
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &:hover,
        &:focus {
          background-color: ${p => p.theme.colors.primaryBackgroundColor} !important;
        }
      }
    }
  }

  ul.features {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: ${p => p.theme.spacing.huge};

    @media (max-width: 1024px) {
      flex-wrap: wrap;

      li {
        margin-bottom: ${p => p.theme.spacing.medium};
      }
    }

    li {
      width: 25%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      align-items: flex-start;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${p => p.theme.colors.secondaryBackgroundColor};
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: ${p => p.theme.spacing.small};

        img {
          max-width: 100px;
          height: auto;
        }
      }

      h3,
      p {
        width: 100%;
      }

      h3 {
        margin-bottom: ${p => p.theme.spacing.small};
      }

      @media (max-width: 1024px) {
        width: 48%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`

export const Plan = styled.div`
  width: 100%;
`
