import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-left: -${p => p.theme.spacing.medium};
  margin-right: -${p => p.theme.spacing.medium};

  @media (max-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .slick-dots {
      bottom: 15px !important;
    }

    > div {
      margin-left: 0 !important;
    }
  }
`
export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  .secondaryBr {
    transition: all 0.3s ease;
    :hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.palette.secondary600};
    }
  }
`

export const FileContainer = styled.div`
  margin: 0 0 1rem 0;
  overflow: hidden;
  ${({ theme }) => ({...theme.size.xs})}

  .img-container {
    display: block;
    padding: 5px 0;

    .label {
      margin-bottom: 5px;
      span {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

  input[type='file'] {
    width: 100%;
    ${({ theme }) => ({...theme.size.xxs})}
  }

  input[type='file']::-webkit-file-upload-button,
  input[type='file']::file-selector-button  {
    background-color: ${({ theme }) => theme.colors.palette.secondary600};
    color: white;
    border: 0;
    padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.small};
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
    }
  }

`
