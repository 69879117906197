import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { MultipleWrapper } from './styles'

function LastLessonsSkeletonComponent() {
  return (
    <MultipleWrapper>
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
      <Skeleton width={255} height={245} baseColor={colors.palette.neutral500} />
    </MultipleWrapper>
  )
}

export const LastLessonSkeleton = memo(LastLessonsSkeletonComponent)
