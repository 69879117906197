import styled from 'styled-components'

interface ImageWrapperProps {
  backgroundImage: string
}

export const Wrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${p => p.theme.spacing.medium};
  overflow: hidden;
  transition: all 0.2s linear;
  margin-right: 16px;
  width: 100%;

  &:focus,
  &:hover {
    background: ${p => p.theme.colors.primaryBackgroundColor};
  }

  img {
    max-width: 100%;
    height: auto;
  }
`

export const ImageWrapper = styled.div<ImageWrapperProps>`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url(${p => p.backgroundImage});
  background-position: center center;
  background-size: cover;
  margin-bottom: ${p => p.theme.spacing.medium};
`

export const ItemTitle = styled.h3`
  width: 100%;
  text-align: center;
  margin-bottom: ${p => p.theme.spacing.small};
  color: ${p => p.theme.colors.white};
  ${p => ({ ...p.theme.size.sm })};

  @media (max-width: 768px) {
    ${p => ({ ...p.theme.size.xs })};
  }
`

export const Occupation = styled.h4`
  text-align: center;
  margin-bottom: ${p => p.theme.spacing.large};
  color: ${p => p.theme.colors.white};
  ${p => ({ ...p.theme.size.xxs })};
  ${p => ({ ...p.theme.typography.primary.normal })};
  padding: 0 ${p => p.theme.spacing.small};

  @media (max-width: 768px) {
    ${p => ({ ...p.theme.size.xxs })};
  }
`
