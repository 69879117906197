import { Settings } from 'react-slick'

export const settings: Settings = {
  infinite: true,
  autoplay: true,
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
      },
    },
  ],
}

export interface ICarouselItem {
  id: string
  imageUrl: string
  title: string
  occupation: string
  link: string
}
