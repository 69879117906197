import styled from 'styled-components'

export const Wrapper = styled.a`
  cursor: pointer;
  overflow: hidden;
  display: flex;
  margin-bottom: ${p => p.theme.spacing.medium};

  img {
    max-width: 100%;
    height: auto;
  }
`
