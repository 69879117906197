import React, { memo } from 'react'
import { Teacher } from 'src/service/api/api.types'
import { ImageWrapper, ItemTitle, Occupation, Wrapper } from './styles'

interface Props {
  data: Teacher
}

function TeacherCarouselItemComponent({ data }: Props) {
  return (
    <Wrapper href={`/professor/${data.slug}`} title={data.name}>
      <ImageWrapper backgroundImage={data.imageUrl} />
      <ItemTitle>{data.name}</ItemTitle>
      <Occupation>{data.occupation}</Occupation>
    </Wrapper>
  )
}

export const TeacherCarouselItem = memo(TeacherCarouselItemComponent)
