import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { ColumnsWrapper } from './styles'

function TestimonialsSkeletonComponent() {
  return (
    <ColumnsWrapper>
      <Skeleton height={420} width="100%" baseColor={colors.palette.neutral500} />
      <Skeleton height={420} width="100%" baseColor={colors.palette.neutral500} />
    </ColumnsWrapper>
  )
}

export const TestimonialsSkeleton = memo(TestimonialsSkeletonComponent)
