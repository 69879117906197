import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { MultipleWrapper } from './styles'

function TeachersSkeletonComponent() {
  return (
    <MultipleWrapper>
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={163} height={256} baseColor={colors.palette.neutral500} />
    </MultipleWrapper>
  )
}

export const TeachersSkeleton = memo(TeachersSkeletonComponent)
