import React from 'react'
import { Carousel } from 'src/components/Common/Carousel'
import { settings } from 'src/carousels/full-width-lessons.carousel'
import { INews } from 'src/service/api/api.types'
import { Card } from 'src/components/Common/Card'
import {Button} from "src/components/Common/Button";
import styled from "styled-components";

interface Props {
  news: INews[]
}

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  .secondaryBr {
    transition: all 0.3s ease;
    :hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.palette.secondary600};
    }
  }
`;

export const News: React.FC<Props> = props => (
  <>
    <Carousel<INews> items={props.news} ItemTemplate={Card} settings={settings} linkType="news" />
    <ButtonArea>
      <Button
        type="button"
        isLink
        href="/noticias-recentes/todas"
        labelTx="home.news.button"
        className="secondaryBr"
        preset="secondaryBg"
      />
    </ButtonArea>
  </>
)
