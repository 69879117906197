import React, { memo, useEffect, useState } from 'react'
import { PlansApi } from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import { Button } from 'src/components/Common/Button'
import { PlansSkeleton } from 'src/components/Skeleton/plans'
import { Title } from 'src/components/Common'
import { Plan, Wrapper } from './styles'

interface PlansComponentProps {
  showFeatures: boolean
}

const PlansComponent: React.FC<PlansComponentProps> = props => {
  const [plans, setPlans] = useState<PlansApi>([])

  useEffect(() => {
    ;(async () => {
      await getPlans()
    })()
  }, [])

  async function getPlans() {
    const res = await apiPublic.getPlans()

    if (res.kind === 'ok') {
      setPlans(res.data)
    }
  }

  return (
    <Wrapper id="assinar">
      <Title tx="plans.title" />
      {plans.length === 0 ? (
        <PlansSkeleton />
      ) : (
        <ul className="plans">
          {plans.map(plan => (
            <li key={plan.id}>
              <Plan>
                {plan?.imageUrl && <img src={plan?.imageUrl} alt={plan.title} title={plan.title} />}
                <h3>{plan.title}</h3>
                <h4>Apenas</h4>

                {plan.installments === 1 && (
                  <>
                    <h5>
                      <strong>{plan.price}</strong>
                    </h5>
                    <p>Valor mensal</p>
                  </>
                )}

                {plan.installments > 1 && (
                  <>
                    <h5>
                      {plan.installments}x de <strong>{plan.installmentPrice}</strong>
                      <p>Valor total de {plan.price}</p>
                    </h5>
                  </>
                )}
              </Plan>
              <Button
                type="button"
                isLink
                target="_blank"
                href={plan.checkoutLink}
                labelTx="plans.button"
                preset="secondaryBg"
                className="secondaryBr"
              />
            </li>
          ))}
        </ul>
      )}

      {props.showFeatures && (
        <ul className="features">
          <li>
            <div className="icon-wrapper">
              <img src="/icone-nivel.png" alt="Nível de Proficiência" />
            </div>
            <h3>Nível de Proficiência</h3>
            <p>Estude do básico ao avançado, ou escolha qual nível iniciar.</p>
          </li>
          <li>
            <div className="icon-wrapper">
              <img src="/icone-instrumentos.png" alt="Variedade de Instrumentos" />
            </div>
            <h3>Variedade de Instrumentos</h3>
            <p>
              Comece pelo seu instrumento de seu interesse e avance em outros.{' '}
              <strong>Seja um músico completo</strong>.
            </p>
          </li>
          <li>
            <div className="icon-wrapper">
              <img src="/icone-video.png" alt="Cursos em Vídeo" />
            </div>
            <h3>Cursos em Vídeo</h3>
            <p>Estude do básico ao avançado, ou escolha qual nível iniciar.</p>
          </li>
          <li>
            <div className="icon-wrapper">
              <img src="/icone-professores.png" alt="Professores Qualificados" />
            </div>
            <h3>Professores Qualificados</h3>
            <p>Todos os professores são profissionais atuantes da música.</p>
          </li>
        </ul>
      )}
    </Wrapper>
  )
}

export const Plans = memo(PlansComponent)
