import React, { memo } from 'react'
import { Carousel } from 'src/components/Common/Carousel'
import { SlideCarouselItem } from 'src/components/Common/SlideCarouselItem'
import { settings } from 'src/carousels/slider.carousel'
import { BannerBeStudent } from 'src/components/Common/BannerBeStudent'
import { ISlider } from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import { useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { SliderContainer, Wrapper } from './styles'

function SliderComponent() {
  const profile = useSelector((state: State) => state.user.profile)
  const isAuthenticated = useSelector((state: State) => state.user.isAuthenticated)
  const [slides, setSlides] = React.useState<ISlider[]>()

  React.useEffect(() => {
    ;(() => fetchSliders())()
  }, [])

  async function fetchSliders() {
    const res = await apiPublic.getSliders()
    if (res.kind === 'ok') {
      setSlides(res.data)
    }
  }

  return (
    <Wrapper>
      {profile && (
        <SliderContainer>
          <Carousel<ISlider>
            items={slides}
            ItemTemplate={SlideCarouselItem}
            settings={settings}
            marginLeft={0}
          />
        </SliderContainer>
      )}
      {/* Vamos desativar temporariamente */}
      {isAuthenticated === false && false && (
        <div className="announcement-container">
          <div className="announcement">
            <BannerBeStudent />
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export const Slider = memo(SliderComponent)
