import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { ColumnsWrapper } from './styles'

function TutorialsSkeletonComponent() {
  return (
    <ColumnsWrapper className="tutorial-skeleton">
      <div className="player-tutorial">
        <Skeleton height={280} width="100%" baseColor={colors.palette.neutral500} />
      </div>
      <div className="nav-tutorial" >
        <Skeleton height={350} width="100%" baseColor={colors.palette.neutral500} />
      </div>
    </ColumnsWrapper>
  )
}

export const TutorialSkeleton = memo(TutorialsSkeletonComponent)
