import React from 'react'
import { Button } from 'src/components/Common/Button'
import {apiPublic} from "src/service/api/api.public";
import {ITutorials} from "src/service/api/api.types";
import ReactPlayer from "react-player";
import {TutorialSkeleton} from "src/components/Skeleton/tutorials";
import { Wrapper, Nav } from './styles'

export const HowItWorks: React.FC = () => {
  const [navigations, setNavigation] = React.useState<ITutorials[]>([]);
  const [vimeoId, setVimeoId] = React.useState('')

  React.useEffect(() => {
    (async () => {
      await getTutorials()
    })()
  }, [])

  async function getTutorials() {
    const res = await apiPublic.getTutorials()
    if (res.kind === 'ok') {
      setNavigation(res.data)
      setVimeoId(res.data[0].video)
    }
  }

  return (
    <>
      {navigations && navigations.length > 0 ? (
        <Wrapper>
          <div className="video-container">
            <div className="video">
              <ReactPlayer
                className="react-player"
                controls
                url={vimeoId}
                width="100%"
                height="100%"
                playing
              />
            </div>
          </div>

          <Nav>
            <ul>
              {navigations.map((item) => (
                <li key={`item-${item.slug}`} className={vimeoId === item.video ? 'active' : ''}>
                  <Button
                    preset="neutral"
                    type="button"
                    label={item.title}
                    onClick={() => setVimeoId(item.video)}
                  />
                </li>
              ))}
            </ul>
          </Nav>
        </Wrapper>
      ) : <TutorialSkeleton />}
    </>
  )
}
