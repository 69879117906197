import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'video' 'menu';
  margin-bottom: ${p => p.theme.spacing.extraLarge};

  gap: 0 30px;

  @media ${device.tablet} {
    grid-template-columns: 55% 1fr;
    grid-template-areas: 'video menu';
  }

  .video-container {
    grid-area: video;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const Nav = styled.nav`
  grid-area: menu;
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: 768px) {
    border-radius: 0;
    margin-top: ${p => p.theme.spacing.large};
  }

  ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    border-radius: 6px;

    li:first-child button {
      border-radius: 0;

      @media ${device.tablet} {
        border-radius: 0.7rem 0.7rem 0 0;
      }
    }

    li:last-child button {
      border-radius: 0 0 0.7rem 0.7rem;
    }

    li.active {
      button {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primaryBackgroundColor};
      }
      #container-button-wrapper {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primaryBackgroundColor};
      }
    }
  }

  li + li {
    button {
      border-top: 1px solid ${({ theme }) => theme.colors.primaryBackgroundColor};
    }
  }

  li {
    text-align: left;
    margin-right: 0;

    #container-button-wrapper {
      display: block;
      padding: 1rem;

      :hover {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primaryBackgroundColor};
      }
    }

    button {
      width: 100%;
      ${({ theme }) => ({ ...theme.size.xs })};
      ${({ theme }) => ({ ...theme.typography.primary.medium })};

      @media ${device.laptop} {
        ${({ theme }) => ({ ...theme.size.sm })}
      }
      display: block;
      text-align: left;
      transition: all 0.2s;
      color: ${({ theme }) => theme.colors.primaryTextColor};
      background: ${({ theme }) => theme.colors.palette.neutral300};
      border-radius: 0;

      div {
        border-radius: 0;
        font-size: 14px;
      }

      :hover {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primaryBackgroundColor};
      }
    }
  }
`
