import React from 'react'
import { settings } from 'src/carousels/testimonials'
import { Carousel } from 'src/components/Common/Carousel'
import { TestimonialCarouselItem } from 'src/components/Common/TestimonialCarouselItem'
import { ITestimonials, ShapeTestimonial } from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import { TestimonialsSkeleton } from 'src/components/Skeleton/testimonials'
import { Button } from 'src/components/Common/Button'
import { Dialog } from 'src/components/Common/Modal'
import Field from 'src/components/Common/InputContainer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useErrors } from 'src/hooks/useErrors'
import { toast } from 'react-toastify'
import { setError } from 'src/store/errors'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { IoMdPhotos } from 'react-icons/io'
import { getTestimonialRequest } from 'src/store/testimonials'
import { State } from 'src/store/rootReducers'
import { Wrapper, ButtonArea, FileContainer } from './styles'

export const StudentTestimonials: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const testimonials = useSelector((state: State) => state.testimonial.testimonials)
  const [isOpen, setIsOpen] = React.useState<boolean | null>(false)
  const [loaded, setLoaded] = React.useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = React.useCallback(
    async (body: ShapeTestimonial) => {
      const formData = new FormData()
      if (body.imageUrl) {
        formData.append('file', body.imageUrl)
      }
      formData.append('name', body.name)
      formData.append('location', body.location)
      formData.append('testimonial', body.testimonial)

      if (!executeRecaptcha) {
        // eslint-disable-next-line no-console
        console.log('Execute recaptcha not yet available')
        return
      }

      const recaptcha = await executeRecaptcha('Testimonial')

      const res = await apiPublic.setTestimonials({
        payload: formData,
        recaptcha,
      })

      if (res.kind === 'ok') {
        toast(t('toast.testimonialSuccess'), { type: 'success' })
      } else {
        dispatch(
          setError({
            response: res,
          }),
        )
      }
      setIsOpen(false)
    },
    [executeRecaptcha],
  )

  useErrors()

  React.useEffect(() => {
    ;(async () => {
      await fetchTestimonials()
    })()
  }, [])

  async function fetchTestimonials() {
    dispatch(getTestimonialRequest())
  }

  const Modal = () => {
    const student = useFormik({
      initialValues: {
        name: '',
        location: '',
        imagUrl: '',
        testimonial: '',
      } as ShapeTestimonial,
      onSubmit: async values => {
        setLoaded(true)
        await handleReCaptchaVerify(values)
        setLoaded(false)
      },
      validationSchema: Yup.object({
        name: Yup.string().required(t('validation.required')),
        location: Yup.string().required(t('validation.required')),
        testimonial: Yup.string().required(t('validation.required')),
      }),
    })

    return (
      <Dialog<ShapeTestimonial>
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formik={student}
        isLoading={loaded}
        title="Preencha o formuário para enviar o seu depoimento"
      >
        <div className="form">
          <FileContainer>
            <label htmlFor="file" className="img-container">
              <div className="label">
                <IoMdPhotos />
                <span>Selecione uma foto</span>
              </div>

              <input
                className="input-file"
                type="file"
                name="imageUrl"
                id="file"
                accept="image/*"
                onChange={event => {
                  if (event.currentTarget.files) {
                    student.setFieldValue('imageUrl', event.currentTarget.files[0])
                  }
                }}
              />
            </label>
          </FileContainer>

          <Field
            name="name"
            component="input"
            preset="primary"
            placeholder="Ex: Nome completo"
            label={t('home.studentsTestimonials.form.name')}
          />
          <Field
            rows="5"
            name="testimonial"
            component="textarea"
            preset="primary"
            placeholder="Ex: O que achou da Plataforma, cursos e dos novos professores"
            label={t('home.studentsTestimonials.form.testimonial')}
          />

          <Field
            name="location"
            component="input"
            preset="primary"
            placeholder="Ex: São Paulo (SP)"
            label={t('home.studentsTestimonials.form.location')}
          />
        </div>
      </Dialog>
    )
  }

  return (
    <>
      {!testimonials ? (
        <TestimonialsSkeleton />
      ) : (
        <Wrapper>
          <Carousel<ITestimonials>
            items={testimonials}
            ItemTemplate={TestimonialCarouselItem}
            settings={settings}
            marginLeft={0}
          />
          <ButtonArea>
            <Button
              type="button"
              labelTx="home.studentsTestimonials.button"
              className="secondaryBr"
              preset="secondaryBg"
              onClick={() => setIsOpen(!isOpen)}
            />
          </ButtonArea>

          <Modal />
        </Wrapper>
      )}
    </>
  )
}
