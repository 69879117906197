import React, { memo } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import { getImageUrl } from 'src/utils/getImageUrl'
import { getPlaceholderImageUrl } from 'src/utils/getPlaceholderImageUrl'
import {ISlider} from "src/service/api/api.types";
import { Wrapper } from './styles'

interface Props {
  data: ISlider
}

function SlideCarouselItemComponent({ data }: Props) {
  return (
    <Wrapper href={data.link} title={data.title}>
      <ProgressiveImage
        src={getImageUrl(data.imageUrl, 741, 417)}
        placeholder={getPlaceholderImageUrl(data.imageUrl, 741, 417)}
        rootMargin="0% 0% 0%"
        threshold={[0.5]}
      >
        {(src) => (
          <img src={src} alt={data.title} />
        )}
      </ProgressiveImage>
    </Wrapper>
  )
}

export const SlideCarouselItem = memo(SlideCarouselItemComponent)
