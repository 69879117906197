import { Settings } from 'react-slick'

export const settings: Settings = {
  infinite: true,
  autoplay: true,
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export interface ICarouselItem {
  id: string
  imageUrl: string
  link: string
  title: string
}
