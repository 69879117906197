import React from 'react'
import { NextPage } from 'next'
import { RecentClasses } from 'src/components/Home/RecentClasses'
import { News } from 'src/components/Home/News'
import { Teachers } from 'src/components/Home/Teachers'
import { StudentTestimonials } from 'src/components/Home/StudentTestimonials'
import { Banners } from 'src/components/Home/Banners'
import { HowItWorks } from 'src/components/Home/HowItWorks'
import PublicLayout from 'src/components/Layout/public'
import { Slider } from 'src/components/Home/Slider'
import Container from 'src/components/Common/Container'
import { Section } from 'src/components/Common/Section'
import { colors } from 'src/theme/colors'
import { apiPublic } from 'src/service/api/api.public'
import { CategoriesApi, CategoriesWithArea, INews } from 'src/service/api/api.types'
import { useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { Plans } from 'src/components/Home/Plans'
import { spacing } from 'src/theme/spacing'
import _ from 'lodash'

interface Props {
  news: INews[]
  categories: CategoriesApi
  tea: CategoriesWithArea
}

const Home: NextPage<Props> = props => {
  const isAuthenticated = useSelector((state: State) => state.user.isAuthenticated)
  const user = useSelector((state: State) => state.user)

  const hasNoPlan = user !== undefined && user.profile?.plans && user.profile.plans.length === 0

  return (
    <PublicLayout
      categories={props.categories}
      customCategories={_.omitBy({ tea: props.tea }, _.isNull)}
    >
      <Container>
        <Section
          padding={`${spacing.huge} 0 ${spacing.small}`}
          titleTx={isAuthenticated === false ? 'home.about.title' : ''}
        >
          {isAuthenticated === false ? <HowItWorks /> : null}
          <Slider />
        </Section>
      </Container>

      {(isAuthenticated === false || hasNoPlan) && (
        <Container>
          <Plans showFeatures />
        </Container>
      )}

      <Section backgroundColor={colors.palette.neutral300} titleTx="home.recentClasses.title">
        <RecentClasses />
      </Section>

      {props.news && props.news.length > 0 && (
        <Section backgroundColor={colors.palette.neutral400} titleTx="home.news.title">
          <News news={props.news} />
        </Section>
      )}

      <Section
        applyBackgroundImage
        preset="dark"
        backgroundColor={colors.secondaryBackgroundColor}
        titleTx="home.teachers.title"
      >
        <Container>
          <Teachers />
        </Container>
      </Section>

      <Container>
        <Section titleTx="home.studentsTestimonials.title">
          <StudentTestimonials />
          <Banners />
        </Section>
      </Container>
    </PublicLayout>
  )
}

export default Home

export const getServerSideProps = async () => {
  const news = await apiPublic.getNewsRecent()
  const categories = await apiPublic.getCategories()
  const tea = await apiPublic.getCategoriesByArea('tea')

  return {
    props: {
      news: 'data' in news && news.data,
      categories: 'data' in categories && categories.data,
      tea: 'data' in tea && tea.data,
    },
  }
}
