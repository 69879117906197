import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { ColumnsWrapper } from './styles'

function BannersSkeletonComponent() {
  return (
    <ColumnsWrapper>
      <Skeleton height={120} width="100%" baseColor={colors.palette.neutral500} />
      <Skeleton height={120} width="100%" baseColor={colors.palette.neutral500} />
    </ColumnsWrapper>
  )
}

export const BannersSkeleton = memo(BannersSkeletonComponent)
