import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import { MultipleWrapper } from './styles'

function PlansSkeletonComponent() {
  return (
    <MultipleWrapper>
      <Skeleton width={350} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={350} height={256} baseColor={colors.palette.neutral500} />
      <Skeleton width={350} height={256} baseColor={colors.palette.neutral500} />
    </MultipleWrapper>
  )
}

export const PlansSkeleton = memo(PlansSkeletonComponent)
