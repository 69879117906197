import React, { PropsWithChildren } from 'react'
import { Popup, Modal } from 'src/components/Common/Modal/styles'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from 'src/components/Common/Button'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'src/components/Common/Form'
import { FormikProps } from 'formik'

interface Props<T> {
  isOpen: boolean | null
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | null>>
  formik?: FormikProps<T>
  isLoading?: boolean
  title?: string
}

export function Dialog<T>(props: PropsWithChildren<Props<T>>) {
  const { children, isOpen, setIsOpen, formik, isLoading, title = 'Envie seus dados' } = props
  const { t } = useTranslation()
  const popup = React.useRef<HTMLDivElement | null>(null)
  const animationModal = !isLoading ? 'open' : ''

  React.useEffect(() => {
    if (popup && popup.current && isOpen) {
      document.body.classList.add('stop-scrolling')
    }

    document.addEventListener('keyup', (e: KeyboardEvent) => {
      const isKey = e.key && (e.key === 'Enter' || e.key === 'Escape')
      const isWhich = e.which && (e.which === 13 || e.which === 27)
      if (isKey || isWhich) {
        setIsOpen(false)
      }
    })

    return () => {
      document.body.classList.remove('stop-scrolling')
      document.removeEventListener('keyup', (e: KeyboardEvent) => e)
    }
  }, [isOpen])

  if (isOpen === null) {
    return null
  }

  const ComponentChildren = formik ? (
    <FormProvider<T> provider={formik}>
      <div className="modal-body">{children}</div>

      <div className="modal-footer">
        <Button
          onClick={() => setIsOpen(false)}
          type="button"
          preset="neutral"
          labelTx={t('home.studentsTestimonials.form.buttonClose')}
        />

        <Button
          isLoading={isLoading || false}
          type="submit"
          preset="form"
          labelTx={t('home.studentsTestimonials.form.buttonSave')}
        />
      </div>
    </FormProvider>
  ) : (
    <div className="modal-body">{children}</div>
  )

  return (
    <Modal className={isOpen ? animationModal : 'closed'}>
      <div className="backdrop" onClick={() => setIsOpen(!isOpen)} />
      <Popup ref={popup} className="modal" role="dialog">
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="btn-close" onClick={() => setIsOpen(!isOpen)}>
            <span>
              <AiOutlineClose />
            </span>
          </button>
        </div>

        {ComponentChildren}
      </Popup>
    </Modal>
  )
}
