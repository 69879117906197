import styled from 'styled-components'

export const CardStyled = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
  padding: ${p => p.theme.spacing.medium} ${p => p.theme.spacing.medium} 0;
  transition: all 0.2s;

  @media (max-width: 767px) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  h3 {
    width: 100%;
    text-align: center;
    ${({ theme }) => ({ ...theme.size.sm })};
    margin-top: ${p => p.theme.spacing.medium} !important;
  }

  small {
    margin-bottom: 0.8rem;
    display: block;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryBackgroundColor};
  }

  h3,
  p {
    margin: 0 auto;
    padding: 0 0.5rem;
    max-width: 90%;
  }

  .balloon {
    position: relative;
    margin-bottom: 0.5rem;
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.colors.palette.neutral300};
    max-width: 100%;

    p {
      text-align: center;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 13;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-wrap: break-word;
      visibility: visible;
      white-space: break-spaces;
      padding: 1.5rem 0 0;
      margin-bottom: 1.5rem;
      color: ${({ theme }) => theme.colors.primaryBackgroundColor};
    }

    .arrow {
      height: 1rem;
      display: flex;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.palette.neutral300};
      border-radius: 0.8rem;

      ::after {
        content: '';
        display: block;
        margin: 12px auto 0;
        border: 20px solid;
        border-color: ${({ theme }) => theme.colors.palette.neutral300} transparent transparent
          transparent;
      }
    }
  }
`
