import styled from 'styled-components'

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    padding-bottom: ${p => p.theme.spacing.extraLarge};

    > div {
      margin-left: 0 !important;
    }
  }
`
