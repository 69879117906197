import React, { memo } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import { getImageUrl } from 'src/utils/getImageUrl'
import { ITestimonials } from 'src/service/api/api.types'
import { CardStyled } from './styles'

interface Props {
  data: ITestimonials
}

const TestimonialCarouselItemComponent = ({ data }: Props) => (
  <CardStyled>
    <div className="balloon">
      <p>{data.testimonial}</p>
      <div className="arrow" />
    </div>
    <div className="img-container">
      {!data.imageUrl ? (
        <img
          src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224793/avatar_zrtqux.png"
          alt="Avatar"
        />
      ) : (
        <ProgressiveImage
          src={getImageUrl(data.imageUrl, 80, 80)}
          placeholder={getImageUrl(data.imageUrl, 80, 80)}
          rootMargin="0% 0% 0%"
          threshold={[0.5]}
        >
          {src => <img src={src} alt={data.name} />}
        </ProgressiveImage>
      )}
    </div>
    <h3>{data.name}</h3>
    <small>{data.location}</small>
  </CardStyled>
)

export const TestimonialCarouselItem = memo(TestimonialCarouselItemComponent)
