import React from 'react'
import { Settings } from 'react-slick'
import { NextArrow } from 'src/components/Common/Carousel/next-arrow'
import { PrevArrow } from 'src/components/Common/Carousel/prev-arrow'

export const settings: Settings = {
  infinite: true,
  speed: 500,
  centerMode: false,
  slidesToScroll: 4,
  slidesToShow: 5,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToScroll: 3,
        slidesToShow: 4,
      },
    },
  ],
}

export interface ICarouselItem {
  id: string
  imageUrl: string
  placeholderImageUrl: string
  title: string
  link: string
}
