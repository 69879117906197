import { Settings } from 'react-slick'

export const settings: Settings = {
  infinite: true,
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export interface ICarouselItem {
  id: string
  imageUrl: string
  name: string
  location: string
  testimonial: string
}
