import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 48px;

  .banner {
    width: 50%;

    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0;

    .banner {
      width: 100%;
      margin-bottom: ${p => p.theme.spacing.medium};
    }
  }
`
