import React from 'react'
import Link from 'next/link'
import { ApiGetBanner } from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import { BannersSkeleton } from 'src/components/Skeleton/banners'
import ProgressiveImage from 'react-progressive-graceful-image'
import { getImageUrl } from 'src/utils/getImageUrl'
import { getPlaceholderImageUrl } from 'src/utils/getPlaceholderImageUrl'
import { Wrapper } from './styles'

export const Banners: React.FC = () => {
  const [banners, setBanners] = React.useState<ApiGetBanner>()

  React.useEffect(() => {
    ;(async () => {
      await getBanners()
    })()
  }, [])

  async function getBanners() {
    const res = await apiPublic.getBanners()
    if (res.kind === 'ok') {
      setBanners(res.data)
    }
  }

  const RenderBanner = () =>
    banners?.data &&
    banners.data.map(item => (
      <div className="banner" key={`banner-${item.id}`}>
        <Link legacyBehavior passHref target="_blank" href={item.link}>
          <a>
            <ProgressiveImage
              src={getImageUrl(item.imageUrl, 570, 120)}
              placeholder={getPlaceholderImageUrl(item.imageUrl, 570, 120)}
              rootMargin="0% 0% 0%"
              threshold={[0.5]}
            >
              {src => <img src={src} alt={item.title} />}
            </ProgressiveImage>
          </a>
        </Link>
      </div>
    ))

  return (
    <>
      <Wrapper>{banners ? RenderBanner() : <BannersSkeleton />}</Wrapper>
    </>
  )
}
