import { device } from 'src/theme/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'slider' 'announcement';
  gap: 1rem 0;
  justify-content: center;

  @media ${device.tablet} {
    gap: 1.5rem;
    grid-template-columns: 65%;
    grid-template-areas: 'slider announcement';
  }

  @media (max-width: 768px) {
    display: block;
  }

  .announcement-container {
    grid-area: announcement;

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      padding-top: ${p => p.theme.spacing.medium};
    }
  }

  .announcement {
    max-width: clamp(100px, 100vw, 100%);
    height: 100%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
`

export const SliderContainer = styled.div`
  grid-area: slider;
  max-width: clamp(100px, 100vw, 100%);

  @media (max-width: 1024px) {
    margin-bottom: ${p => p.theme.spacing.large};
  }

  @media (max-width: 768px) {
    > div {
      margin-left: 0 !important;
    }
  }
`
