import React from 'react'
import { Carousel } from 'src/components/Common/Carousel'
import { settings } from 'src/carousels/teachers.carousel'
import { TeacherCarouselItem } from 'src/components/Common/TeacherCarouselItem'
import { apiPublic } from 'src/service/api/api.public'
import { ITeachers, Teacher } from 'src/service/api/api.types'
import { TeachersSkeleton } from 'src/components/Skeleton/teachers'
import { Wrapper } from './styles'

export function Teachers() {
  const [teachers, setTeachers] = React.useState<ITeachers>()

  React.useEffect(() => {
    (async () => {
      await getTeachers()
    })()
  }, [])

  async function getTeachers() {
    const res = await apiPublic.getTeachers()
    if (res.kind === 'ok') {
      setTeachers(res.data)
    }
  }

  return (
    <Wrapper>
      {!teachers ? (
        <TeachersSkeleton />
      ) : (
        <Carousel<Teacher>
          items={teachers}
          ItemTemplate={TeacherCarouselItem}
          settings={settings}
          marginLeft={0}
        />
      )}
    </Wrapper>
  )
}
