import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const Popup = styled.div`
  background: white;
  width: 600px;
  max-width: 98%;
  max-height: 98%;
  border-radius: 0.5rem;
  z-index: 9999;
  box-shadow: 0 5px 10px #555;
  padding: 1rem;
  overflow: hidden;

  div.modal-body {
    overflow: auto;
    width: 100%;
    height: auto;
    max-height: 55vh;
    padding: 0 0.5rem 0 0;
    margin: 0 auto;

    @media ${device.laptop} {
      max-height: 70vh;
      padding: 0;
    }
  }

  .catalog {
    margin-bottom: 0.5rem;

    li + li {
      margin-top: 1rem;
    }

    a {
      text-decoration: underline;
      ${({ theme }) => ({ ...theme.size.xxs })}
      color: ${({ theme }) => theme.colors.palette.secondary600};

      @media ${device.tablet} {
        ${({ theme }) => ({ ...theme.size.xs })}
      }

      &:hover {
        color: ${({ theme }) => theme.colors.palette.primary600};
      }
    }
  }
`

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  display: none;

  .backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background: #00000055;
  }

  &.open,
  &.open .backdrop {
    display: flex;
    visibility: visible;
    animation: backdrop 0.3s;
    .modal {
      visibility: visible;
      animation: modal 0.3s;
    }

    &.open .form > *,
    &.open .modal-footer {
      visibility: visible;
    }
  }

  &.closed,
  &.closed .backdrop {
    visibility: hidden;
    animation: backdrop 0.3s reverse;
    .modal {
      visibility: hidden;
      animation: modal 0.3s reverse;
    }
    &.closed .form > *,
    &.closed .modal-footer {
      visibility: hidden;
    }
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      ${({ theme }) => ({ ...theme.size.xs })}
      max-width: 80%;

      @media ${device.tablet} {
        ${({ theme }) => ({ ...theme.size.md })}
      }
    }

    .btn-close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      border-radius: 50%;

      span {
        display: contents;
      }
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    button + button {
      margin-left: 1rem;
    }

    button {
      flex: 1;

      @media ${device.laptop} {
        flex: none;
      }

      div {
        width: 100%;

        @media ${device.laptop} {
          width: auto;
        }
      }
    }
  }

  @keyframes modal {
    from {
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, -60px, 0);
    }
    to {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }
  }
  @keyframes backdrop {
    from {
      visibility: hidden;
      opacity: 0;
    }
    to {
      visibility: visible;
      opacity: 1;
    }
  }
`
