import React from 'react'
import { Carousel } from 'src/components/Common/Carousel'
import { settings } from 'src/carousels/full-width-lessons.carousel'
import { Card } from 'src/components/Common/Card'
import { Lesson } from 'src/service/api/api.types'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { getLastLessonRequest } from 'src/store/lessons'
import { LastLessonSkeleton } from 'src/components/Skeleton/lastLessons'

export function RecentClasses() {
  const dispatch = useDispatch()
  const lastLessons = useSelector((state: State) => state.course.lastLessons)
  const isLoading = useSelector((state: State) => state.course.isLoading)

  React.useEffect(() => {
    ;(() => fetchLastLessons())()
  }, [])

  async function fetchLastLessons() {
    dispatch(getLastLessonRequest())
  }

  const renderLessons = () =>
    lastLessons ? (
      <Carousel<Lesson>
        items={lastLessons}
        ItemTemplate={Card}
        settings={settings}
        linkType="lesson"
      />
    ) : null

  return <>{isLoading ? <LastLessonSkeleton /> : renderLessons()}</>
}
